<template>
    <loading-animation v-if="DataLoading"/>
    <div v-else class="card bs-4 p-4">
        <h4 class="mb-3 text-primary">Terms & Privacy Policy</h4>
        <div class="row">
            <div class="col-12">
                <file-input ref="file" class="c-file-input" label="Terms & Conditions" :file_name="termsFile" name="Terms"
                            v-model="model.terms" :disabled="loading || loading1"/>
            </div>
            <div class="col-12 text-right">
                <btn v-if="model.terms" size="md" @click="updateClicked('terms')" text="Update Terms & Conditions"
                     :disabled="loading || loading1" :loading="loading1"
                     loading-text="Updating Terms & Conditions..." class="w-15r"/>
                <btn v-if="termsFile" size="md" @click="viewFile('terms')" title="View current Terms & Conditions" class="ml-1"
                     :disabled="loading || loading1"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <file-input ref="file1" class="c-file-input" label="Privacy Policy" name="Privacy" :file_name="privacyFile"
                            v-model="model.privacy" :disabled="loading || loading1"/>
            </div>
            <div class="col-12 text-right">
                <btn v-if="model.privacy" size="md" @click="updateClicked('privacy')" text="Update privacy policy"
                     :disabled="loading || loading1" :loading="loading"
                     loading-text="Updating Privacy Policy..." class="w-15r"/>
                <btn v-if="privacyFile" size="md" @click="viewFile('privacy')" class="ml-1"
                     :disabled="loading || loading1" title="View current privacy policy"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'TermsAndPrivacy',
    data () {
        return {
            loading: false,
            loading1: false,
            DataLoading: false,
            model: {},
            addEdit: urls.admin.policy.addEdit,
            privacyId: '',
            privacyFile: '',
            termsId: '',
            termsFile: '',
            action: '',
            details: ''
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.get(urls.admin.policy.list);
            const json = response.data;
            if (json) {
                that.details = json.data;
                if (that.details.length !== 0) {
                    that.setId(that.details);
                }
            } else {
                that.$notify('Details not found, Please try again later.', 'No Data Found', {
                    type: 'info'
                });
            }
            that.DataLoading = false;
        },
        setId (item) {
            for (let i = 0; i <= item.length - 1; i++) {
                if (item[i].policy === 'privacy') {
                    this.privacyId = item[i].id;
                    this.privacyFile = item[i].file;
                }
                if (item[i].policy === 'terms') {
                    this.termsId = item[i].id;
                    this.termsFile = item[i].file;
                }
            }
        },
        viewFile (item) {
            if (item === 'privacy') {
                window.open(this.privacyFile);
            }
            if (item === 'terms') {
                window.open(this.termsFile);
            }
        },
        async updateClicked (item) {
            this.getFormUrl(item);
            const that = this;
            that.model.policy = item;
            const response = await axios.form(that.addEdit, that.model);
            const json = response.data;
            if (json.error === false) {
                that.formSuccess(item, json);
            } else {
                that.formError(json);
                that.loading = false;
                that.loading1 = false;
            }
            that.loading = false;
            that.loading1 = false;
        },
        getFormUrl (item) {
            if (item === 'privacy') {
                this.loading = true;
                if (this.privacyId !== '') {
                    this.model.id = this.privacyId;
                    if (this.model.privacy) {
                        this.model.file = this.model.privacy;
                    } else {
                        delete this.model.file;
                    }
                    delete this.model.privacy;
                } else {
                    this.model.file = this.model.privacy;
                    delete this.model.privacy;
                }
            } else {
                this.loading1 = true;
                if (this.termsId !== '') {
                    this.model.id = this.termsId;
                    if (this.model.terms) {
                        this.model.file = this.model.terms;
                    } else {
                        delete this.model.file;
                    }
                    delete this.model.terms;
                } else {
                    this.model.file = this.model.terms;
                    delete this.model.terms;
                }
            }
        },
        formError (json) {
            if (json.errors.__all__) {
                this.$notify((json.errors.__all__) + ' field is required', 'Error', {
                    type: 'danger'
                });
            } else {
                this.$notify('Could not update, Please try again later.', 'Error', {
                    type: 'danger'
                });
            }
        },
        formSuccess (item, json) {
            this.$notify('Updated Successfully', 'Success', {
                type: 'success'
            });
            this.loadDetails();
        }
    }
};
</script>

<style scoped>

</style>
